<template>
	<div class="d-flex flex-grow-1 h-100 align-items-center justify-content-center p-5">
		<div class="p-5 rounded" style="max-width: 550px; background-color: var(--color-900);">
			<div class="display-1 mb-4">Migrate</div>
			<div class="lead text-muted mb-5">
				A tool for transferring stylesheets from the legacy extension to the web app.
			</div>
			<Onboarding migrateOnly="true" />
		</div>
	</div>
</template>

<script>
	const Onboarding = () => import('@/components/OnboardingPath');
	export default {
		name: 'Migrate',
		components: {
			Onboarding
		}
	}
</script>

<style lang="scss" scoped>
	
</style>
