<template>
<div>
	<div class="text-left" v-if="migrateOnly">
		<div v-if="migrating">
			<div class="loader local my-5">
				<div class="spinner"></div>
			</div>
			<div class="text-muted mt-4" v-if="finalizingMigration">Finalizing transfer</div>
			<div class="text-muted mt-4" v-else-if="activeMigration">Transferring stylesheets for {{ activeMigration }}</div>
		</div>
		<div class="options text-left" v-else>
			<div v-if="!migrated">
				<div class="text-center p-5 bg-dark rounded">
					<div class="h5 mb-2">Data Migration</div>
					<div class="mb-4 text-muted">This is a tool for transferring stylesheets from the legacy extension to the web app. If you were an Amino user prior to version 3.0, this tool is for you.</div>
					<b-button variant="primary" @click.prevent="migrate">Migrate Data</b-button>
				</div>
			</div>
			<div v-else>
				<template v-if="migratedStyles > 0">
					<div class="display-1 mt-5">
						{{ migratedStyles }}
					</div>
					<div class="mt-1 mb-5 lead text-muted">
						stylesheets imported successfully
					</div>
				</template>
				<template v-else-if="migratedStyles > -1">
					<div class="h2 mb-4">
						We were unable to locate any stylesheets to transfer.
					</div>
					<div class="lead text-muted">
						If you think you have stylesheets to transfer, read our document on <a href="https://docs.aminoeditor.com/transferring-stylesheets" target="_blank">transferring stylesheets</a> for help.
					</div>
				</template>
				<div class="my-4" v-else-if="cantMigrate">
					<div class="h5 mb-1">Unsupported Browser</div>
					<div class="text-muted">
						It looks like you're using an unsupported browser. Please refer to our document on <a href="https://docs.aminoeditor.com/transferring-stylesheets" target="_blank">transferring stylesheets</a> for help.
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="text-left" v-else>
		<b-modal id="onboarding-modal" centered ref="my-modal" no-close-on-backdrop no-close-on-esc hide-footer>
			<div class="h4 m-0" v-if="migrating">Transfer in progress</div>
			<div class="text-left" v-else>
				<img v-lazy="logo" width="24">
			</div>
			<div v-if="migrating">
				<div class="loader local my-5">
					<div class="spinner"></div>
				</div>
				<div class="text-muted mt-4" v-if="finalizingMigration">Finalizing transfer</div>
				<div class="text-muted mt-4" v-else-if="activeMigration">Transferring stylesheets for {{ activeMigration }}</div>
			</div>
			<div class="options text-left" v-else>
				<div v-if="!this.user.migrated && !this.dontMigrate && !this.cantMigrate">
					<div class="h2 mt-5 mb-3">Hey, welcome!</div>
					<div class="lead text-muted mb-5">Tell us a little about yourself.</div>
					<div @click.prevent="skipMigrate" class="path-btn border p-4 mb-4">
						<b-row>
							<b-col>
								<div class="h5 mb-1">I'm new to Amino.</div>
								<div class="text-muted">I'll be starting fresh.</div>
							</b-col>
							<b-col cols="auto" class="align-self-center">
								<span class="material-icons-outlined md-light">east</span>
							</b-col>
						</b-row>
					</div>
					<div @click.prevent="migrate" class="path-btn border p-4">
						<b-row>
							<b-col>
								<div class="h5 mb-1">I'm a returning Amino user.</div>
								<div class="text-muted">Take me to my library.</div>
							</b-col>
							<b-col cols="auto" class="align-self-center">
								<span class="material-icons-outlined md-light">east</span>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-else>
					<template v-if="migratedStyles > 0">
						<div class="display-1 mt-5">
							{{ migratedStyles }}
						</div>
						<div class="mt-1 mb-5 lead text-muted">
							stylesheets imported successfully
						</div>
					</template>
					<template v-else-if="migratedStyles > -1">
						<div class="display-2 mt-5 mb-3">
							We were unable to locate any stylesheets to transfer.
						</div>
						<div class="mt-1 mb-5 lead text-muted">
							If you think you have stylesheets to transfer, we advise you to keep the extension installed. Read our document on <a href="https://docs.aminoeditor.com/transferring-stylesheets" target="_blank" style="color: inherit; text-decoration: underline;">transferring stylesheets</a> for help. If you are still unable to successfully transfer your stylesheets, send us a message at <a href="mailto:support@aminoeditor.com"  style="color: inherit; text-decoration: underline;">support@aminoeditor.com</a> so we can help you recover your data.
						</div>
					</template>
					<div class="my-4" v-else-if="cantMigrate">
						<div class="h5 mb-1">Unsupported Browser</div>
						<div class="text-muted">
							It looks like you're using an unsupported browser, please read our docs for more info...
						</div>
					</div>

					<!-- Eric: This is what we're keeping -->
					<div class="my-4" v-else-if="dontMigrate">
						<div class="h1 mt-5 mb-2">Hey, welcome!</div>
						<div class="lead text-muted mb-5">It's good to have you. Let's get you started.</div>
					</div>
					<div class="my-4">
						<a @click.prevent="getBasic" class="path-btn border p-4 d-block">
							<b-row>
								<b-col>
									<div class="h5 mb-1">I need to learn the basics.</div>
									<div class="text-muted">Get a brief intro on how to use Amino.</div>
								</b-col>
								<b-col cols="auto" class="align-self-center">
									<span class="material-icons-outlined md-light">east</span>
								</b-col>
							</b-row>
						</a>
					</div>
					<div>
						<a @click.prevent="completeOnboarding" class="path-btn border p-4 d-block">
							<b-row>
								<b-col>
									<div class="h5 mb-1">I'm good to go.</div>
									<div class="text-muted">Just take me to my library.</div>
								</b-col>
								<b-col cols="auto" class="align-self-center">
									<span class="material-icons-outlined md-light">east</span>
								</b-col>
							</b-row>
						</a>
					</div>
					<!-- End -->

				</div>
			</div>
		</b-modal>
	</div>
</div>
</template>

<script>
	const logo = require('../assets/images/icon.svg');
	export default {
		name: 'OnboardingPath',
		props: ['migrateOnly'],
		data() {
			return {
				migratedStyles: -1,
				dontMigrate: true,
				migrating: false,
				activeMigration: false,
				finalizingMigration: false,
				migrated: false,
				logo
			};
		},
		computed: {
			cantMigrate() {
				return (!window.chrome || !window.chrome.runtime);
			},
			Styles() {
				return this.$FeathersVuex.api.Styles;
			}
		},
		methods: {
			showModal() {
				if (typeof this.$refs['my-modal'] !== "undefined") {
					this.$refs['my-modal'].show();
				}
			},
			hideModal() {
				if (typeof this.$refs['my-modal'] !== "undefined") {
					this.$refs['my-modal'].hide();
				}
			},
			skipMigrate() {
				this.dontMigrate = true;
				this.user.migrated = true;
				this.user.save();
			},
			getBasic() {
				this.completeOnboarding();
				this.$router.push('/onboarding');
			},
			completeOnboarding() {
				this.hideModal();
				this.user.onboarded = true;
				this.user.save();
			},
			migrate() {
				this.migrating = true;
				if (window.chrome && window.chrome.runtime && (!this.user.migrated || (this.migrateOnly && !this.migrated))) {
					window.chrome.runtime.sendMessage(this.extensionId, {
						migrate: true
					}, async items => {
						const deleteStyleKeys = [];
						const ignoreKeys = ['apiAccessToken'];
						for (let key in items) {
							if (!ignoreKeys.includes(key) && !key.match('___extension')) {
								this.activeMigration = key;
								if ((typeof items[key] === 'string' || items[key] instanceof String) && items[key].length) {
									try {
										const res = await this.Styles.find({
											query: {
												key
											}
										});
										let migrateStyle;
										if (res.total > 0) {
											migrateStyle = new this.Styles(res.data[0]);
											migrateStyle.val = migrateStyle.val + "\n\n" + "/* MIGRATED STYLES */" + "\n" + items[key];
										} else {
											migrateStyle = new this.Styles();
											migrateStyle.key = key;
											migrateStyle.val = items[key];
											migrateStyle.mode = key.match(/^http/) ? 0 : 1;
											migrateStyle.active = true;
											migrateStyle.draft = false;
											migrateStyle.isFavorite = false;
										}
										await migrateStyle.save();
										deleteStyleKeys.push(key);
									} catch (e) {
										console.log('MIGRATION ERROR:', e.message);
									}
								} else {
									deleteStyleKeys.push(key);
								}
							}
						}
						this.finalizingMigration = true;
						this.migratedStyles = deleteStyleKeys.length;
						window.chrome.runtime.sendMessage(this.extensionId, {
							deleteStyleKeys
						});
						this.user.migrated = true;
						this.migrated = true;
						await this.user.save();
						this.migrating = false;
					});
				}
			},
			beforeUnload() {
				if (this.user.migrated) {
					this.completeOnboarding();
				}
			}
		},
		mounted() {
			this.showModal();
			window.addEventListener("beforeunload", this.beforeUnload);
		},
		unmounted() {
			window.removeEventListener("beforeunload", this.beforeUnload);
		}
	}
</script>

<style lang="scss" scoped>
.loader {
    &.local {
        background: transparent;
        position: relative;
    }
}
#onboarding-modal___BV_modal_outer_ {
    z-index: 9999999999999 !important;
    & /deep/ #onboarding-modal {
        .modal-content {
            border-radius: 1rem !important;
        }
        .modal-body {
            padding: 2.5rem !important;
        }
    }
}
.display-1 {
    font-size: 6rem;
}
.display-2 {
    font-size: 3rem;
    line-height: 1.1;
}
.path-btn {
    background: var(--color-700);
    border-color: var(--color-500) !important;
    cursor: pointer;
	border-radius: 0.5rem;
    // transition: var(--property-transition);
    &:hover {
        background: var(--color-500);
        border-color: var(--color-500) !important;
        text-decoration: none;
    }
}
</style>
