import {
    extend,
    ValidationObserver,
    ValidationProvider
} from 'vee-validate';

import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

extend('min', {
    validate(value, {
        length
    }) {
        return value.length >= length;
    },
    params: ['length'],
    message: 'The {_field_} field must have at least {length} characters'
});

extend('match', {
    params: ['target', 'label'],
    validate(value, {
        target
    }) {
        return value === target;
    },
    message: '{label} does not match'
});

export { ValidationObserver, ValidationProvider }
