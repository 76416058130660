<template>
<div class="d-flex flex-column h-100">
    <NavbarWorkspace v-if="isAuthenticated" />
    <NavbarMain v-else />
    <main class="main-column flex-grow-1">
        <div class="loader" v-if="!loaded">
            <div class="spinner"></div>
        </div>
        <Onboarding v-if="this.isAuthenticated && !this.user.onboarded" />
        <div class="d-flex flex-row h-100">
            <SidebarWorkspace v-if="isAuthenticated" />
            <div class="d-flex flex-grow-1 main-content">
                <router-view></router-view>
                <!-- <transition name="component-fade" mode="out-in">

					</transition> -->
            </div>
        </div>
    </main>
</div>
</template>

<script>
const NavbarWorkspace = () => import('@/components/NavbarWorkspace');
const NavbarMain = () => import('@/components/NavbarMain');
const SidebarWorkspace = () => import('@/components/SidebarWorkspace');
const Onboarding = () => import('@/components/OnboardingPath');
export default {
    name: 'Master',
    data() {
        return {
            value: 33.333333333,
            max: 100,
            loaded: false
        }
    },
    components: {
        NavbarWorkspace,
        NavbarMain,
        SidebarWorkspace,
        Onboarding
    },
    computed: {
        pageTitle() {
            return this.$route.meta.page.title;
        },
        hasSearch() {
            return this.$route.meta.hasSearch || false;
        },
        Styles() {
            const {
                Styles
            } = this.$FeathersVuex.api;
            return Styles;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
                .catch(() => {})
                .then(() => {
                    this.$router.push('sign-in');
                });
        },
        toast() {
            this.$bvToast.toast(`Toast with action link`, {
                href: '#foo',
                title: 'Example'
            })
        },
    },
    async mounted() {
        this.loaded = true;
    }
}
</script>

<style lang="scss" scoped>
.main-content {
    padding-left: 260px;
    padding-top: 116px;
    @media (max-width: 900px) {
        padding: 0;
    }
}
</style>
