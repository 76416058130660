<template>
<div>
    <div class="header text-center">
        <b-container>
            <div class="display-2 mb-3">
                Notifications
            </div>
			<div class="lead m-0 text-muted">
                Stay up to date with new features and releases.
            </div>
        </b-container>
    </div>
    <div class="notifications-list" v-if="notifications">
        <b-container class="document p-0">
            <div class="bg-dark item rounded" v-for="notification in notifications" v-bind:key="notification._id" v-bind:class="{ 'unread': !isSeen(notification) }">
                <div class="mb-4" v-if="notification.img && notification.img.length">
                    <img class="img-fluid rounded" :src="notification.img" />
                </div>
                <div class="h2">{{ notification.title }}</div>
                <div class="description mt-2">{{ notification.body }}</div>
                <div class="external-link mt-3" v-if="notification.link.length"><a :href="notification.link">{{ notification.link }}</a></div>
                <div class="small text-muted mt-3">{{ notification.createdAt | moment("from", "now") }}</div>
            </div>
			<ModuleFollow />
        </b-container>
        <b-pagination v-if="totalNotifications > 15" v-model="currentPage" :total-rows="totalNotifications" :per-page="notificationsPerPage" class="mt-3"></b-pagination>
    </div>

</div>
</template>

<script>
const ModuleFollow = () => import('@/components/ModuleFollow');
export default {
    name: 'Notifications',
    data() {
        return {
            notifications: false,
            totalNotifications: 0,
            currentPage: 1,
            notificationsPerPage: 15
        };
    },
	components: {
        ModuleFollow
    },
    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        NotificationAggregate() {
            const {
                NotificationAggregate
            } = this.$FeathersVuex.api;
            return NotificationAggregate;
        },
        Notifications() {
            const {
                Notifications
            } = this.$FeathersVuex.api;
            return Notifications;
        },
        ReadNotification() {
            const {
                ReadNotification
            } = this.$FeathersVuex.api;
            return ReadNotification;
        }
    },
    methods: {
        loadNotifications() {
            this.NotificationAggregate.find({
                query: {
                    $limit: this.notificationsPerPage,
                    $skip: ((this.currentPage - 1) * this.notificationsPerPage)
                }
            }).then(res => {
                const [notifications] = res;
                this.notifications = notifications.data;
                this.totalNotifications = notifications.metadata[0].total;
            });
        },
        markNotificationsRead() {
            for (let notification of this.notifications) {
                if (notification.readBy.length === 0) {
                    notification.readBy.push(this.user._id);
                    const readNotification = new this.ReadNotification;
                    readNotification.id = notification._id;
                    readNotification.create();
                }
            }
        },
        isSeen(notification) {
            return notification.readBy.length;
        }
    },
    watch: {
        currentPage() {
            this.loadNotifications();
        }
    },
    async mounted() {
        this.loadNotifications();
        this.Notifications.on('created', this.loadNotifications);
        this.Notifications.on('updated', this.loadNotifications);
    },
    beforeDestroy() {
        this.markNotificationsRead();
        this.Notifications.off('created', this.loadNotifications);
        this.Notifications.off('updated', this.loadNotifications);
    }
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.document {

    a {
        color: inherit;
        text-decoration: underline;
    }
}
.container {
    max-width: 700px;
}

.notifications-list {
	padding: 0 2rem;
    .title {
        font-size: 1.25rem;
    }
    .item {
        padding: 2rem;
		border: 1px solid var(--color-500);
        &.unread {
            .h2 {
                display: inline-block;
                position: relative;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 20px;
                    transform: translate(2rem, -50%);
                    content: "";
                    display: block;
                    background-color: var(--color-primary);
                }
            }
        }
        .description,
        .text-muted {
            color: var(--color-100) !important;
        }
        &:not(:last-of-type) {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
        }
    }
}
</style>
