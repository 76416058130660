<template>
<div class="d-flex w-100 h-100">
	<transition name="component-fade" mode="out-in">
		<div class="w-100 d-flex flex-column">
			<div class="amino-disabled-alert" v-if="options.disabled">
				<div class="interior rounded small">
					You have disabled Amino from within the editor. You are now unable to modify or save new styles.
					<a class="ml-1 cursor-pointer" @click.prevent="enableAmino">
						Enable Amino
						<span class="material-icons-outlined">east</span>
					</a>
				</div>
			</div>

			<div class="amino-disabled-alert" v-if="!installed">
				<div class="interior rounded small">
					The Amino browser extension is not installed.
					<a class="ml-1" :href="getExtensionLink('chrome')" target="_blank">
						Install it now
						<span class="material-icons-outlined">east</span>
					</a>
				</div>
			</div>

			<div v-if="activeCollection" class="h-100 d-flex flex-column flex-grow-1">
				<header class="header">
					<div class="d-flex">
						<div class="display-2 mb-0">
							{{ collection.name }}
						</div>
						<div class="arrow-button ml-3 d-flex align-items-center justify-content-center" v-b-modal="'collection-edit'">
							<span class="material-icons-outlined md-light">edit</span>
						</div>
					</div>
				</header>
				<div class="position-relative">
					<!-- <div class="loader" :class="{ invisible: !loading }">
						<div class="spinner"></div>
					</div> -->
					<StyleList v-if="styles.length" :styles="styles" :disabled="options.disabled" :mode="styleDisplayMode" :draft="filter === 'drafts'" />
				</div>
				<div class="text-center" v-if="styles.length">
					<b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="secondary" class="d-inline-block">
						<b-button style="margin-bottom: 2.5rem;" variant="dark" v-waypoint="{ active: true, callback: infiniteScroll, options: intersectionOptions }" @click.prevent="loadMoreStyles" v-if="styles.length < total">
							Load more
						</b-button>
					</b-overlay>
				</div>
				<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center text-center" v-if="activeCollection && !styles.length  && !loading">
					<div class="h2">You haven’t added any stylesheets to this collection.</div>
				</div>
			</div>
			<div v-else-if="!activeCollection" class="d-flex h-100 mb-4">
				<div v-if="!isPro" class="d-flex flex-column align-items-center justify-content-center text-center flex-grow-1" style="padding: 2rem;">
					<div class="mb-5 rounded" style="max-width: 450px; background-color: var(--color-900);">
						<img class="img-fluid" src="@/assets/images/collections-preview.png">
					</div>
					<div class="display-3 mb-3" style="line-height: 1.1;">
						Get organized with collections
					</div>
					<div style="max-width: 380px;">
						<div class="lead text-muted mb-4">
							Unlock new functionality by grouping your work into collections.
						</div>
						<b-button type="submit" variant="primary" to="/pro">Get Amino Pro</b-button>
					</div>
				</div>
				<div class="w-100" v-else>
					<CollectionsList />
				</div>
			</div>
		</div>
	</transition>
	<Editor :options="options" />
	<CollectionEditModal :item="collection.clone()" v-if="collection" />
</div>
</template>

<script>
import {
	mapActions
} from 'vuex';
import Clipboard from 'clipboard';
const Editor = () => import('@/components/Editor');
const StyleList = () => import('@/components/StyleList');
const CollectionsList = () => import('@/components/CollectionsList');
const CollectionEditModal = () => import('@/components/CollectionEditModal');
export default {
	name: 'Library',
	components: {
		Editor,
		StyleList,
		CollectionsList,
		CollectionEditModal
	},
	data() {
		return {
			styles: [],
			collection: false,
			limit: 10,
			page: 1,
			total: 0,
			styleDisplayMode: 'grid',
			searchText: this.$route.query.search || '',
			loading: true,
			pluginConfigs: {
				whitespace: {
					'remove-trailing': true,
					'remove-indent': true,
					'left-trim': true,
					'right-trim': true
				}
			},
			sampleCode: "@keyframes spin {\n\tfrom { transform: rotate(0deg); }\n\tto { transform: rotate(360deg); }\n}\n\n.logo {\n\tanimation-name: spin;\n\tanimation-duration: 4000ms;\n\tanimation-iteration-count: infinite;\n}",
			options: false,
			intersectionOptions: {
				root: null,
				rootMargin: '0px 0px 0px 0px',
				threshold: 1
			},
			activeKeys: [],
			installed: true,
			clipboard: false
		};
	},
	computed: {
		Options() {
			return this.$FeathersVuex.api.Options;
		},
		Styles() {
			const {
				Styles
			} = this.$FeathersVuex.api;
			return Styles;
		},
		Collections() {
			return this.$FeathersVuex.api.Collections;
		},
		filter: {
			get() {
				return typeof this.$route.query.filter === 'undefined' ? 'all' : this.$route.query.filter;
			},
			set() {
				return;
			}
		},
		collectionMode() {
			return this.$route.name === 'collections';
		},
		activeCollection() {
			return typeof this.$route.params.collection_id === "undefined" ? false : this.$route.params.collection_id;
		},
		styleParams() {
			const styleParams = {
				query: {}
			};
			switch (this.filter) {
				case 'sites':
					styleParams.query.mode = 1;
					break;
				case 'pages':
					styleParams.query.mode = 0;
					break;
				case 'favorites':
					styleParams.query.isFavorite = true;
					break;
				case 'drafts':
					styleParams.query.draft = {
						$ne: false
					};
					break;
			}
			if (this.filter != 'drafts') {
				styleParams.query.val = {
					$ne: ''
				};
			}
			if (this.searchText) {
				styleParams.query['$or'] = [{
					key: {
						$regex: this.searchText
					}
				}, {
					val: {
						$regex: this.searchText
					}
				}];
			}
			if (this.collection) {
				styleParams.query._id = {
					$in: this.collection.styles
				}
			}
			styleParams.query.$limit = this.limit;
			styleParams.query.$skip = (this.page - 1) * this.limit;
			styleParams.query.$sort = {
				updatedAt: -1
			};

			return styleParams;
		}
	},
	methods: {
		loadOptions() {
			this.Options.find().then(res => {
				this.options = res.data[0] || false
			});
		},
		enableAmino() {
			this.options.disabled = false;
			this.options.save();
		},
		loadStyles() {
			this.loading = true;
			this.activeKeys = [];
			setTimeout(() => {
				this.findStyles(this.styleParams).then(styles => {
					this.total = styles.total;
					this.styles = styles.data;
					for (let style of this.styles) {
						this.activeKeys.push(style.key);
					}
					this.loading = false;
				});
			}, 200);
		},
		async loadCollection() {
			if (this.activeCollection) {
				this.collection = await this.Collections.get(this.activeCollection);
			}
		},
		infiniteScroll({
			going,
			direction
		}) {
			if (going == 'in' && typeof direction !== "undefined") {
				this.loadMoreStyles();
			}
		},
		loadMoreStyles() {
			this.loading = true;
			setTimeout(() => {
				this.page++;
				this.findStyles(this.styleParams).then(styles => {
					this.total = styles.total;
					this.styles = this.styles.concat(styles.data);
					for (let style of styles.data) {
						this.activeKeys.push(style.key);
					}
					this.loading = false;
				});
			}, 200);
		},
		loadCreatedStyles(style) {
			this.styles.unshift(style);
			this.total++;
			this.activeKeys.push(style.key);
		},
		removeStyles(style) {
			this.styles.splice(
				this.styles.findIndex(function(o) {
					return o.id === style.id;
				}),
				1
			);
			this.total--;
			this.activeKeys.splice(this.activeKeys.indexOf(style.key), 1);
		},
		toggleActiveStyle(style) {
			style.active = !style.active;
			style.patch();
		},
		routeFilter(filter) {
			this.$router.replace({
				query: {
					filter
				}
			});
		},
		search() {
			let query = Object.assign({}, this.$route.query, {
				search: this.searchText
			});
			if (!this.searchText.length) {
				delete query.search;
			}
			this.$router.push({
				query
			});
			this.loadStyles();
		},
		toggleStyleDisplayMode() {
			this.styleDisplayMode = this.styleDisplayMode === 'grid' ? 'list' : 'grid';
		},
		...mapActions('styles', {
			findStyles: 'find',
			saveStyles: 'patch'
		})
	},
	watch: {
		/*filter: function() {
			this.loadStyles();
		},
		search: function() {
			this.loadStyles();
		}*/
	},
	async beforeRouteUpdate(to, from, next) {
		this.loading = true;
		this.collection = false;
		next();
		await this.loadCollection();
		this.loadStyles();
	},
	async mounted() {
		this.clipboard = new Clipboard('.copy');
		this.extensionExists().then(installed => {
			this.installed = installed;
		});
		this.loadOptions();
		if (this.activeCollection) {
			await this.loadCollection();
			this.loadStyles();
		} else {
			this.collection = new this.Collections;
		}
		this.Collections.on('patched', this.loadStyles);
		this.Styles.on('created', this.loadCreatedStyles);
		this.Styles.on('removed', this.removeStyles);
	},
	unmounted() {
		this.clipboard.destroy();
		this.Collections.off('patched', this.loadStyles);
		this.Styles.off('created', this.loadCreatedStyles);
		this.Styles.off('removed', this.removeStyles);
	}
}
</script>

<style scoped lang="scss">
.header {
	padding: 0 1.5rem 2rem;
	.display-2 {
		font-size: 2.5rem;
		line-height: 1;
	}
}

.search-filters {
	display: flex;
	width: 100%;
	padding: 0 1.5rem 1.5rem;
	.btn.btn-dark {
		min-width: 70px;
		background-color: transparent;
		border-color: var(--color-500);
		&:active,
		&:focus {
			border-color: var(--color-500) !important;
		}
		&.active {
			background-color: var(--color-700) !important;
			border-color: var(--color-500) !important;
		}
	}
	.filters {
		.btn.btn-dark {
			text-transform: none !important;
			letter-spacing: normal !important;
		}
		.item:not(:last-of-type) {
			margin-right: 0.75rem;
		}
	}
}

.btn.btn-outline-dark {
	text-transform: none !important;
	letter-spacing: normal !important;
	color: white;
}

.amino-disabled-alert {
	padding: 0 1.5rem;
	margin-bottom: 2rem;
	.interior {
		font-weight: var(--font-weight-bold);
		background-color: var(--color-700) !important;
		border: 1px solid var(--color-500);
		padding: 1rem !important;
		border-radius: 2rem;
	}
	a {
		text-decoration: none;
		color: var(--color-100);
		&:hover {
			color: var(--color-white);
		}
		.material-icons-outlined {
			vertical-align: middle;
			font-size: inherit !important;
		}
	}
}
</style>
