<template>
<div class="account">
    <NavbarMain />
    <div class="container">
        <header class="section-header d-flex flex-column justify-content-between">
            <div class="d-flex align-items-center">
                <div>
                    <b-avatar variant="dark" size="lg" :src="user.profilePicture" v-if="user.profilePicture"></b-avatar>
                    <b-button class="header-btn d-flex align-items-center justify-content-center" size="lg" variant="dark" pill v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 24px;">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>
                    </b-button>
                </div>
                <div class="ml-4">
                    <div class="h5 mb-1">
						{{ user.email }}
					</div>
					<div class="text-muted" v-if="isPastDue">
						Amino Pro
						<b-badge variant="primary ml-2">Account past due</b-badge>
                    </div>
                    <div class="text-muted" v-else-if="isPro">
						Amino Pro
					</div>
                    <div class="text-muted" v-else>
						Amino Basic
					</div>
                </div>
            </div>
        </header>
        <div class="tabs-container">
            <b-tabs pills vertical>
                <b-tab :active="tab === 'settings'" @click="$router.push('/account')">
                    <template #title>
                        Settings
                    </template>
                    <div class="h3 mb-2">Email Address</div>
                    <div class="mb-4 text-muted">This is where all notifications will be sent, including lost password requests.</div>
                    <ValidationObserver v-slot="{ handleSubmit, reset, pristine, failed }">
                        <b-form @submit.prevent="handleSubmit(changeEmail)" @reset.prevent="reset">
                            <div class="mb-4" role="group">
                                <ValidationProvider v-slot="v" name="Email" rules="required|email">
                                    <b-form-input trim v-model="email" :disabled="changeRequest !== false || !isVerified" :state="getValidationState(v)"></b-form-input>
                                    <b-form-invalid-feedback class="mt-2">
                                        {{ v.errors[0] }}
                                    </b-form-invalid-feedback>
                                </ValidationProvider>
                            </div>
                            <b-overlay :show="loading" opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                <b-button variant="primary" type="submit" size="sm" :disabled="email === user.email || pristine || failed" v-if="!changeRequest">Change Email</b-button>
                            </b-overlay>
                        </b-form>
                    </ValidationObserver>
					<div class="mt-4 small" v-if="changeRequest">
                        <div class="mb-1">
                            You have a pending email change request. Check your inbox for a confirmation email.
                        </div>
                        <a class="text-primary" href="" @click.prevent="resendVerification('change-email')">Resend confirmation email</a>
                        <a class="text-muted ml-4" href="" @click.prevent="cancelEmailChange">Cancel email change</a>
                    </div>
                    <div class="mt-4 small" v-if="!isVerified">
                        <div class="mb-1">
							Verify your email address to access all account features.
						</div>
						<div>
							<a class="text-primary" href="#" @click.prevent="resendVerification('registration')">Resend confirmation email</a>
						</div>
                    </div>
                    <hr class="mt-5 mb-5">
                    <div class="h3 mb-2">Change Password</div>
                    <div class="mb-4 text-muted">Enter both fields to change your password.</div>
                    <ValidationObserver v-slot="{ handleSubmit, reset, pristine, failed }">
                        <b-form ref="passwordForm" @submit.prevent="handleSubmit(changePassword)" @reset.prevent="reset">
                            <div class="mb-4" role="group">
                                <ValidationProvider v-slot="v" name="Password" rules="required|min:8">
                                    <b-form-input type="password" v-model="password" trim placeholder="New Password" :state="getValidationState(v)"></b-form-input>
                                    <b-form-invalid-feedback class="mt-2">
                                        {{ v.errors[0] }}
                                    </b-form-invalid-feedback>
                                </ValidationProvider>
                            </div>
                            <div class="mb-4" role="group">
                                <ValidationProvider v-slot="v" name="Confirm Password" rules="required|match:@Password,Password">
                                    <b-form-input type="password" v-model="confirmPassword" trim placeholder="Confirm New Password" :state="getValidationState(v)"></b-form-input>
                                    <b-form-invalid-feedback class="mt-2">
                                        {{ v.errors[0] }}
                                    </b-form-invalid-feedback>
                                </ValidationProvider>
                            </div>
                            <b-overlay :show="loading" opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                <b-button variant="primary" size="sm" type="submit" :disabled="pristine || failed">Change Password</b-button>
                            </b-overlay>
                        </b-form>
                    </ValidationObserver>
                    <hr class="mt-5 mb-5">
                    <div class="h3 mb-2">Danger Zone</div>
                    <div class="text-muted small" v-b-modal.modal-delete-account>Delete Your Account</div>
                </b-tab>

                <b-tab :active="tab === 'plans'" @click="$router.push('/account/plans')">
                    <template #title>
                        Plans
                    </template>
                    <div class="p-5 bg-dark rounded">
                        <PaymentsForm v-if="isAuthenticated" />
                    </div>
                </b-tab>

                <b-tab title="Data" :active="tab === 'data'" @click="$router.push('/account/data')">
                    <OnboardingPath :migrateOnly="true" />
                    <!-- <div class="text-center p-5 bg-dark rounded mb-5">
							<div class="h5 mb-2">Export your data</div>
							<div class="mb-4 text-muted">Get a .zip of all your stylesheets</div>
							<b-button pill variant="primary">Export Data</b-button>
						</div>
						<h5>Folder Key</h5>
						<table class="mt-3 mb-0 table legend">
							<thead>
								<tr>
									<th scope="col">Filename</th>
									<th scope="col">Purpose</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>_root.css</td>
									<td class="text-muted">Styles that apply to the domain root ("/").</td>
								</tr>
								<tr>
									<td>_global.css</td>
									<td class="text-muted">Styles that apply to all pages on a given domain.</td>
								</tr>
							</tbody>
						</table> -->
                </b-tab>
            </b-tabs>
        </div>
    </div>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "@/modules/vendor/validate";
import OnboardingPath from '@/components/OnboardingPath';
const NavbarMain = () => import('@/components/NavbarMain');

const PaymentsForm = () => import('@/components/PaymentsForm');
export default {
    name: 'UserAccount',
    data() {
        return {
            changeRequest: false,
            email: '',
            password: '',
            loading: false,
            confirmPassword: ''
        };
    },
    components: {
        NavbarMain,
        OnboardingPath,
        PaymentsForm,
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        ChangeEmail() {
            return this.$FeathersVuex.api.ChangeEmail;
        },
        ResendVerification() {
            return this.$FeathersVuex.api.ResendVerification;
        },
        user() {
            return this.$store.getters["auth/user"] || {};
        },
        tab() {
            return this.$route.name;
        }
    },
    methods: {
        async cancelEmailChange() {
            await this.changeRequest.remove();
            this.changeRequest = false;
            this.email = this.user.email;
        },
        async changeEmail() {
            this.loading = true;
            const email = this.email;
            this.changeRequest = await new this.ChangeEmail({
                email
            }).create();
            this.loading = false;
        },
        async resendVerification(type) {
            this.loading = true;
            const resend = new this.ResendVerification;
            resend.type = type;
            resend.create().then(res => {
                this.loading = false;
                if (res.sent === true) {
                    this.makeToast('Confirmation email sent');
                } else {
                    this.makeToast('There was an error sending the confirmation email. Please try again');
                }
            });
        },
        async changePassword() {
            this.loading = true;
            this.user.password = this.password;
            await this.user.save();
            this.loading = false;
            this.password = this.confirmPassword = '';
            this.$refs.passwordForm.reset();
            this.makeToast('Password updated');
        }
    },
    async mounted() {
        this.email = this.user.email.toString();
        this.ChangeEmail.find().then(async res => {
            if (res.total > 0) {
                this.changeRequest = res.data[0];
                if (this.$route.name === 'approve-email') {
                    try {
                        this.changeRequest.key = this.$route.params.key;
                        await new this.ChangeEmail(this.changeRequest).update();
                        this.$router.push('/account');
                        this.user.email = this.email = this.changeRequest.email;
                        this.changeRequest = false;
                        this.makeToast('Email updated');
                    } catch (e) {
                        this.$router.push('/account');
                        this.makeToast('Invalid verification link');
                    }
                }
            }
        });
    }
}
</script>

<style lang="scss" scoped>
.account {
    padding-bottom: 5rem;
}
.section-header {
    padding: 5rem 0;
    @media (max-width: 767px) {
        padding: 3rem 0;
    }
}
.container {
    max-width: 850px !important;
    margin: auto;
}
</style>

<style lang="scss">
@media (max-width: 767px) {
    .tabs {
        display: block !important;
        margin: 0 !important;
		.col-auto {
			padding: 0 !important;
		}
		.nav {
			flex-direction: row !important;
			li:not(:last-of-type) {
				margin-right: 0.75rem;
			}
		}
    }
}
.tabs-container {
    .nav {
        width: 190px;
        margin: 0;
        background-color: var(--color-1000);
		margin-right: 1rem;
        @media (max-width: 767px) {
            width: 100%;
            margin: 0 0 1.5rem;
        }
    }

    .card-header {
        background: transparent;
        padding: 0;
        border: 0;
    }

    .tab-content {
        border: 1px solid var(--color-700);
        padding: 2rem;
        border-radius: var(--property-border-radius);
    }

    .nav {
        flex-direction: column;

        .nav-link {
            letter-spacing: 0 !important;
            font-size: 1rem;
            font-weight: var(--font-weight-bold);
            padding: 0.75rem;
            &:not(:last-of-type) {
                margin-bottom: 0.5rem;
            }
            &.active {
                background-color: var(--color-700);
                color: var(--color-white);
            }
        }

        .material-icons-outlined {
            margin-right: 0.75rem;
        }

        &:not(.active) {
            a {
                color: var(--color-white);
            }
        }
    }

    .card-header-pills {
        margin: 0;
        padding: 0;
    }

    .card-body {
        padding: 0;
    }

    .nav-link {
        padding: 0;
    }
}

.table th {
    border-top: 0;
}
</style>
