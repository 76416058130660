<template>
<div class="d-flex w-100 h-100">
	<div class="d-flex flex-column w-100 h-100">
		<div class="amino-disabled-alert" v-if="options.disabled">
			<div class="interior rounded small">
				You have disabled Amino from within the editor. You are now unable to modify or save new styles.
				<a style="white-space: nowrap;" class="cursor-pointer" @click.prevent="enableAmino">
					Enable Amino&nbsp;<span class="material-icons-outlined">east</span>
				</a>
			</div>
		</div>

		<div class="amino-disabled-alert" v-if="!installed">
			<div class="interior rounded small">
				The Amino browser extension is not installed.
				<a class="ml-1" :href="getExtensionLink('chrome')" target="_blank">
					Install it now
					<span class="material-icons-outlined">east</span>
				</a>
			</div>
		</div>

		<header class="d-flex align-items-center justify-content-between header">
			<div class="display-2 m-0">
				Library
			</div>
			<div class="search-filters d-flex">
				<div>
					<b-form @submit.prevent="search">
						<b-form-group class="omnibox position-relative m-0">
							<span class="material-icons-outlined">search</span>
							<b-form-input v-model="searchText" placeholder="Search Library" autocomplete="off"></b-form-input>
						</b-form-group>
					</b-form>
				</div>
				<div class="custom-select-wrapper">
					<b-form-select @change="routeFilter($event)" v-model="filter">
						<b-form-select-option selected value="all">All</b-form-select-option>
						<b-form-select-option value="pages">Pages</b-form-select-option>
						<b-form-select-option value="sites">Sites</b-form-select-option>
						<b-form-select-option value="favorites">Favorites</b-form-select-option>
						<b-form-select-option value="drafts">Drafts</b-form-select-option>
					</b-form-select>
				</div>
			</div>
		</header>

		<div class="d-flex flex-column flex-grow-1" v-if="(filter || styles.length)">
			<div class="w-100 position-relative">
				<!-- <div class="loader d-flex h-100 w-100 align-self-center justify-self-center" :class="{ invisible: !loading }">
					<div class="spinner"></div>
				</div> -->
				<StyleList v-if="styles.length" :styles="styles" :disabled="options.disabled" :mode="styleDisplayMode" :draft="filter === 'drafts'" />
			</div>
			<div class="text-center" v-if="styles.length">
				<b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="secondary" class="d-inline-block">
					<b-button variant="dark" v-waypoint="{ active: true, callback: infiniteScroll, options: intersectionOptions }" @click.prevent="loadMoreStyles" v-if="styles.length < total">
						Load more
					</b-button>
				</b-overlay>
			</div>
			<!-- <div class="loader" :class="{ invisible: !loading }"> -->
			<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center" v-if="filter === 'all' && !styles.length  && !loading">
				<div class="d-flex flex-column align-items-center text-center px-4">
					<div class="display-3 mb-2">
						You have no stylesheets.
					</div>
					<div class="mb-5" style="max-width: 450px;">
						<div class="mt-2 lead text-muted">
							Want to give it a spin? Copy the following CSS into the editor and save. Voilà, take a look at our logo!
						</div>
					</div>
					<div class="demo-stylesheet p-4">
						<div class="copy" v-b-tooltip.left.hover :title="'Copy to Clipboard'" :data-clipboard-text="sampleCode" @click="makeToast('Copied to clipboard');">
							<span class="material-icons-outlined">copy</span>
						</div>
						<prism ref="prism" language="css" class="allow-select" :code="sampleCode" :plugins="pluginConfigs"></prism>
					</div>
				</div>
			</div>
			<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center text-center" v-else-if="filter === 'sites' && !styles.length && !loading">
				<div class="h2 m-0">You haven’t created any site-specific stylesheets.</div>
			</div>
			<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center text-center" v-else-if="filter === 'pages' && !styles.length  && !loading">
				<div class="h2 m-0">You haven’t created any page-specific stylesheets.</div>
			</div>
			<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center text-center" v-else-if="filter === 'favorites' && !styles.length  && !loading">
				<div class="h2 m-0">You haven't favorited any stylesheets.</div>
			</div>
			<div class="d-flex h-100 flex-grow-1 align-items-center justify-content-center text-center" v-else-if="filter === 'drafts' && !styles.length  && !loading">
				<div class="h2 m-0">You don't have any drafts.</div>
			</div>
		</div>
		<Ad class="ad w-100" type="carbon" :key="$route.path" v-slot="{ ad }" show>
			<a class="helper rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_self" v-if="['custom', 'default'].includes(ad.rendering)">
				<div>
					<div class="d-inline-block logo-wrapper rounded p-3" :style="{ 'background-color': ad.backgroundColor}">
						<img :src="`${apiURL}/cdn/img/${base64Encode(ad.logo)}`" title="Sponsored By Carbon" class="d-block" style="max-width: 125px;" />
					</div>
				</div>
				<div class="w-100 d-flex flex-column">
					<div class="description">
						{{ ad.description }}
					</div>
					<div class="meta text-muted">
						Sponsored by Carbon
					</div>
				</div>
			</a>
			<a class="helper smaller rounded d-flex align-items-center" style="gap: 1.5rem" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_blank" v-if="ad.rendering == 'carbon'">
				<div class="logo-wrapper">
					<img :src="`${apiURL}/cdn/img/${base64Encode(ad.smallImage)}`" title="Sponsored By Carbon" class="d-block rounded" style="max-width: 130px;" />
				</div>
				<div>
					<div class="description">
						{{ ad.description }}
					</div>
					<div class="meta text-muted">
						Sponsored by Carbon
					</div>
				</div>
			</a>
		</Ad>
	</div>
	<!-- End: Library -->
	<Editor :options="options" />
</div>
</template>

<script>
import {
	mapActions
} from 'vuex';
import Clipboard from 'clipboard';
const Ad = () => import('@/components/Ad');
const Editor = () => import('@/components/Editor');
const StyleList = () => import('@/components/StyleList');
import {
	VuePrismComponent as prism
} from '@aminoeditor/vue-prism';
export default {
	name: 'Library',
	components: {
		Editor,
		StyleList,
		Ad,
		prism
	},
	data() {
		return {
			styles: [],
			collection: false,
			limit: 50,
			page: 1,
			total: 0,
			styleDisplayMode: 'grid',
			searchText: this.$route.query.search || '',
			loading: true,
			showAd: true,
			pluginConfigs: {
				whitespace: {
					'remove-trailing': true,
					'remove-indent': true,
					'left-trim': true,
					'right-trim': true
				}
			},
			sampleCode: "@keyframes spin {\n\tfrom { transform: rotate(0deg); }\n\tto { transform: rotate(360deg); }\n}\n\n.logo {\n\tanimation: 4000ms spin infinite;\n}",
			options: false,
			intersectionOptions: {
				root: null,
				rootMargin: '0px 0px 0px 0px',
				threshold: 1
			},
			activeKeys: [],
			installed: true,
			clipboard: false
		};
	},
	computed: {
		Options() {
			return this.$FeathersVuex.api.Options;
		},
		Styles() {
			const {
				Styles
			} = this.$FeathersVuex.api;
			return Styles;
		},
		Collections() {
			return this.$FeathersVuex.api.Collections;
		},
		filter: {
			get() {
				return typeof this.$route.query.filter === 'undefined' ? 'all' : this.$route.query.filter;
			},
			set() {
				return;
			}
		},
		collectionMode() {
			return this.$route.name === 'collections';
		},
		activeCollection() {
			return typeof this.$route.params.collection_id === "undefined" ? false : this.$route.params.collection_id;
		},
		styleParams() {
			const styleParams = {
				query: {}
			};
			switch (this.filter) {
				case 'sites':
					styleParams.query.mode = 1;
					break;
				case 'pages':
					styleParams.query.mode = 0;
					break;
				case 'favorites':
					styleParams.query.isFavorite = true;
					break;
				case 'drafts':
					styleParams.query.draft = {
						$ne: false
					};
					break;
			}
			if (this.filter != 'drafts') {
				styleParams.query.val = {
					$ne: ''
				};
			}
			if (this.searchText) {
				styleParams.query['$or'] = [{
					key: {
						$regex: this.searchText
					}
				}, {
					val: {
						$regex: this.searchText
					}
				}];
			}
			if (this.collection) {
				styleParams.query._id = {
					$in: this.collection.styles
				}
			}
			styleParams.query.$limit = this.limit;
			styleParams.query.$skip = (this.page - 1) * this.limit;
			styleParams.query.$sort = {
				updatedAt: -1
			};

			return styleParams;
		}
	},
	methods: {
		loadOptions() {
			this.Options.find().then(res => {
				this.options = res.data[0] || false
			});
		},
		enableAmino() {
			this.options.disabled = false;
			this.options.save();
		},
		loadStyles() {
			this.loading = true;
			this.activeKeys = [];
			setTimeout(() => {
				this.findStyles(this.styleParams).then(styles => {
					this.total = styles.total;
					this.styles = styles.data;
					for (let style of this.styles) {
						this.activeKeys.push(style.key);
					}
					this.loading = false;
					console.log(this.styles);
				});
			}, 200);
		},
		async loadCollection() {
			if (this.activeCollection) {
				this.collection = await this.Collections.get(this.activeCollection);
			}
		},
		infiniteScroll({
			going,
			direction
		}) {
			if (going == 'in' && typeof direction !== "undefined") {
				this.loadMoreStyles();
			}
		},
		loadMoreStyles() {
			this.loading = true;
			setTimeout(() => {
				this.page++;
				this.findStyles(this.styleParams).then(styles => {
					this.total = styles.total;
					this.styles = this.styles.concat(styles.data);
					for (let style of styles.data) {
						this.activeKeys.push(style.key);
					}
					this.loading = false;
				});
			}, 200);
		},
		loadCreatedStyles(style) {
			if (style?.val?.length && !this.styles.some(s => s._id === style._id)) {
				this.styles.unshift(new this.Styles(style));
				this.total++;
				this.activeKeys.push(style.key);
			}
		},
		removeStyles(style) {
			this.styles.splice(
				this.styles.findIndex(function(o) {
					return o.id === style.id;
				}),
				1
			);
			this.total--;
			this.activeKeys.splice(this.activeKeys.indexOf(style.key), 1);
		},
		toggleActiveStyle(style) {
			style.active = !style.active;
			style.patch();
		},
		routeFilter(filter) {
			this.$router.replace({
				query: {
					filter
				}
			});
		},
		search() {
			let query = Object.assign({}, this.$route.query, {
				search: this.searchText
			});
			if (!this.searchText.length) {
				delete query.search;
			}
			this.$router.push({
				query
			});
			this.loadStyles();
		},
		toggleStyleDisplayMode() {
			this.styleDisplayMode = this.styleDisplayMode === 'grid' ? 'list' : 'grid';
		},
		...mapActions('styles', {
			findStyles: 'find',
			saveStyles: 'patch'
		})
	},
	watch: {
		/*filter: function() {
			this.loadStyles();
		},
		search: function() {
			this.loadStyles();
		}*/
	},
	async beforeRouteUpdate(to, from, next) {
		this.loading = true;
		this.collection = false;
		next();
		this.loadStyles();
		await this.loadCollection();
	},
	async mounted() {
		this.clipboard = new Clipboard('.copy');
		this.extensionExists().then(installed => {
			this.installed = installed;
		});
		if (this.$route.query.context && this.$route.query.context == 'extension') {
			this.loginExtension(this.$store.state.auth.accessToken);
			let query = Object.assign({}, this.$route.query);
			delete query.context;
			this.$router.replace({
				query
			});
		}
		this.removeGoogleOneTap();
		if (this.collectionMode && this.activeCollection) {
			await this.loadCollection();
		}
		this.loadOptions();
		if (!this.collectionMode || this.activeCollection) {
			this.loadStyles();
		}
		this.Styles.on('created', this.loadCreatedStyles);
		this.Styles.on('patched', this.loadCreatedStyles);
		this.Styles.on('removed', this.removeStyles);
	},
	unmounted() {
		this.clipboard.destroy();
		this.Styles.off('created', this.loadCreatedStyles);
		this.Styles.off('patched', this.loadCreatedStyles);
		this.Styles.off('removed', this.removeStyles);
	}
}
</script>

<style scoped lang="scss">
.header {
	padding: 0 1.5rem 2rem;
	.display-2 {
		font-size: 2.5rem;
		line-height: 1;
	}
}
.omnibox {
	margin-left: 0.75rem;
	.material-icons-outlined {
		position: absolute;
		top: 50%;
		font-size: 1rem;
		color: var(--color-100);
		left: 0.65rem !important;
		transform: translateY(-50%);
	}

	.form-control {
		background-color: transparent;
		font-size: 0.8rem;
		border: 1px solid var(--color-500);
		// line-height: 2.2rem;
		height: 2.85rem;
		font-weight: var(--font-weight-bold);
		padding-left: 2.15rem;
		color: var(--color-body);
		transition: var(--property-transition);
		box-shadow: none;
		min-width: 200px;

		&:focus {
			color: inherit;
			background-color: var(--color-700);
			outline: 0;
			box-shadow: none;
		}
	}
}

.search-filters {
	padding-left: 1.5rem;
	@media (max-width: 900px) {
		display: none !important;
	}
	.btn.btn-dark {
		min-width: 70px;
		font-size: 0.75rem;
		background-color: transparent;
		border-color: var(--color-500);
		&:active,
		&:focus {
			border-color: var(--color-500) !important;
		}
		&.active {
			background-color: var(--color-700) !important;
			border-color: var(--color-500) !important;
		}
	}
	.filters {
		.btn.btn-dark {
			text-transform: none !important;
			letter-spacing: normal !important;
		}
		.item:not(:last-of-type) {
			margin-right: 0.75rem;
		}
	}
}

.omnibox .material-icons-outlined {
	left: 1.5rem;
}

.btn.btn-outline-dark {
	text-transform: none !important;
	letter-spacing: normal !important;
	color: var(--color-white);
}

.onboarding-modal {
	left: 0;
	background: rgba(32,34,37,0.85);
}

.demo-stylesheet {
	position: relative;
	background: var(--color-700);
	width: 100%;
	max-width: 400px;
	margin: auto;
	border-radius: 1rem;
	.copy {
		position: absolute;
		top: 1rem;
		right: 0;
		padding: 0 1rem;
		cursor: pointer;
	}
	.material-icons-outlined {
		font-size: 1rem;
		color: var(--color-100);
		&:hover {
			color: var(--color-white);
		}
	}
}

.amino-disabled-alert {
	padding: 0 1.5rem;
	margin-bottom: 2rem;
	.interior {
		font-weight: var(--font-weight-bold);
		background-color: var(--color-700) !important;
		border: 1px solid var(--color-500);
		padding: 1rem !important;
		border-radius: 2rem;
	}
	a {
		text-decoration: none;
		color: var(--color-100);
		&:hover {
			color: var(--color-white);
		}
		.material-icons-outlined {
			vertical-align: middle;
			font-size: inherit !important;
		}
	}
}

.copy {
	display: block;
	font-weight: var(--font-weight-bold);
	.feather {
		margin-right: 10px;
	}
}

.custom-select-wrapper {
	margin-left: 1rem;
}

.ad {
	display: none;
	@media (max-width: 899px) {
		display: block !important;
	}
}
.helper {
	
	// max-width: 480px;
	margin: 2rem auto 0;
	padding: 1.25rem;
	background-color: var(--color-500);
	.logo-wrapper {
		box-shadow: inset rgba(0, 0, 0, 0.05) 0 0 0 1px;
		border-radius: 4px;
		img {
			min-width: 3rem;
		}
	}
	.description {
		font-size: 0.8rem;
		line-height: 1.4;
	}
	.meta {
		font-size: 0.65rem;
		line-height: 1.4;
		margin-top: 0.5rem;
	}
	a {
		color: inherit;
		text-decoration: none;
		color: var(--color-text);
	}
}

</style>
